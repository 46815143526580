import React from "react";

const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>Porque usar Grava.la?</h2>
                <p className="lead">
                  Há muitos motivos para escolher o Grava.la, mas destacamos três pontos principais que fazem a diferença para o seu negócio:
                </p>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-signal text-white"></span>
                </div>
                <h5>Sem fio</h5>
                <p>Nossa comunicação totalmente sem fio permite que você mova o botão para qualquer lugar com facilidade.</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-layout-slider-alt text-white"></span>
                </div>
                <h5>Compacto</h5>
                <p>
                  Nossa tecnologia é compacta, simples e eficiente. Com apenas uma câmera e um botão, você tem tudo o que precisa.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-medall text-white"></span>
                </div>
                <h5>Alta Qualidade</h5>
                <p>
                  Pequeno no tamanho, mas grandioso na qualidade. Os clipes são gravados em alta resolução, garantindo que cada momento seja capturado com clareza excepcional.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
