import Contact from "../../components/contact/Contact";
import FeatureImgThree from "../../components/features/FeatureImgThree";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Subsribe from "../../components/newsletter/Subsribe";
import Promo from "../../components/promo/Promo";
import VideoPromoTwo from "../../components/promo/VideoPromoTwo";
import HeroFour from "./HeroFour";

export default function HomeFour() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroFour />
      <Promo />
      <FeatureImgThree bgColor="gray-light-bg"/>
      <VideoPromoTwo />
      <Contact/>
      <Subsribe />
      <Footer space/>
    </Layout>
  );
}
