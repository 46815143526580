import React from "react";

export default function FeatureImgThree({ bgColor }) {
  return (
    <>
      <section id="features" className={`feature-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-4">
              <div className="download-img">
                <img
                  src="assets/img/image-10.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <h2>Processo Fácil com os melhores recursos</h2>
                <p>
                  Nossa tecnologia é fácil de usar e oferece valor profissional. 
                  Grava.la proporciona um serviço sem fio, sem complicações e benefícios exclusivo dos nossos recursos.
                </p>

                <div className="feature-content-wrap">
                  <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active h6"
                        href="#tab6-1"
                        data-toggle="tab"
                      >
                        <span className="ti-video-camera"></span>
                        Camera
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-2"
                        data-toggle="tab"
                      >
                        <span className="ti-mobile"></span>
                        Aplicativo
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-3"
                        data-toggle="tab"
                      >
                        <span className="ti-settings"></span>
                        Instalação
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-4"
                        data-toggle="tab"
                      >
                        <span className="ti-bar-chart"></span>
                        Marketing
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content feature-tab-content">
                    <div className="tab-pane active" id="tab6-1">
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-control-record rounded mr-3 icon icon-color-1"></span>
                          <h5 className="mb-0">Qualidade</h5>
                        </div>
                        <p>
                          Nossa camera é compacta mas com alta qualidade de imagem, com resolução de 1080p.
                          Para um bom resultado, recomendamos que a camera seja instalada em um local estratégico, que permita uma visão ampla do ambiente.
                        </p>
                      </div>
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-signal rounded mr-3 icon icon-color-1"></span>
                          <h5 className="mb-0">Conectividade</h5>
                        </div>
                        <p>
                          A comunicação da camera é via Wi-Fi, garantindo que as gravações sejam feitas de forma rápida e segura até os nossos servidores.
                          Além de conter uma antena com protocolo unico para a comunicação com o botão de gravação sem interferência de outros dispositivos.
                        </p>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab6-2">
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-calendar rounded mr-3 icon icon-color-1"></span>
                          <h5 className="mb-0">Eventos</h5>
                        </div>
                        <p>
                          Através do nosso aplicativo, o usuário pode criar eventos e, com um QR Code disponível no 
                          estabelecimento, associar a câmera ao evento. Dessa forma, todas as gravações feitas pela 
                          câmera serão vinculadas ao evento, garantindo que somente os participantes tenham acesso às gravações, 
                          mantendo a privacidade e segurança.
                        </p>
                      </div>
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-video-clapper rounded mr-3 icon icon-color-1"></span>
                          <h5 className="mb-0">Gravações</h5>
                        </div>
                        <p>
                          As gravações são feitas de forma automática, basta o usuário acionar o botão de gravação sem fio.
                          O usuário pode visualizar as gravações feitas pela câmera, podendo baixar e compartilhar com quem desejar.
                        </p>
                      </div>

                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-download rounded mr-3 icon icon-color-1"></span>
                          <h5 className="mb-0">Download</h5>
                        </div>
                        <p>
                          As gravações permanecem disponíveis por 2 dias, após esse período, são excluídas automaticamente.
                        </p>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab6-3">
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-package rounded mr-3 icon icon-color-1"></span>
                          <h5 className="mb-0">Fácil de instalar</h5>
                        </div>
                        <p>
                          O Grava.la é um sistema muito simples de instalar, você receberá uma central com a camera e um botão de gravação sem fio.
                        </p>
                        <p>
                          Escolha o ponto estratégico para instalar a camera, para este caso é necessário um ponto de energia e conexão com a internet.
                          O botão de gravação sem fio pode ser colocado em qualquer lugar, pois ele se conecta com a camera via protocolo de comunicação.
                        </p>
                        <p>
                          Com a camera e o botão de gravação instalados, basta baixar o aplicativo e seguir as instruções para vincular a camera ao aplicativo e rede.
                        </p>
                        <p>
                          Recomendamos que o botão fique em um local de fácil acesso para o usuário, para que ele possa acionar a gravação de forma rápida e prática.
                        </p>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab6-4">
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-medall rounded mr-3 icon icon-color-1"></span>
                          <h5 className="mb-0">Construir Credibilidade</h5>
                        </div>
                        <p>
                          Mostre aos seus clientes que você valoriza cada momento importante. 
                          Ao exibir vídeos de alta qualidade dos momentos cruciais, você demonstra profissionalismo e atenção aos detalhes.
                        </p>
                      </div>
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-thumb-up rounded mr-3 icon icon-color-1"></span>
                          <h5 className="mb-0">Aumentar a Confiança</h5>
                        </div>
                        <p>
                          Compartilhar momentos autênticos e espontâneos ajuda a criar uma conexão emocional com seu público. 
                          Clientes que veem esses momentos únicos sendo registrados tendem a confiar mais na sua marca e nos seus serviços.
                        </p>
                      </div>
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-stats-up rounded mr-3 icon icon-color-1"></span>
                          <h5 className="mb-0">Melhorar a Retenção de Clientes</h5>
                        </div>
                        <p>
                          Ao mostrar os melhores momentos capturados, você mantém seu público engajado e interessado. 
                          Isso não só atrai novos clientes, mas também mantém os atuais satisfeitos e mais propensos a retornar.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
